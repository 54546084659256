/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-has-content */

import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import { Spinner } from "./common"

const Button = ({ className, submit, slot, children, loading, ...rest }) => {
  let Element

  const selector = classNames("button", className, { slot, loading })

  if (submit || rest.type === "submit") {
    Element = ({ ...props }) => <button type="submit" {...props} />
  } else if (rest.to) {
    Element = ({ ...props }) => <Link {...props} />
  } else {
    Element = ({ ...props }) => <a {...props} />
  }

  return (
    <Element className={selector} {...rest}>
      {slot && <div className="button-slot">{slot}</div>}
      {children && <span className="children">{children}</span>}
      {loading && <Spinner />}
    </Element>
  )
}

export default Button
