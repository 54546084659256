/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-has-content */

import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import classNames from "classnames"
import { PortalWithState as CommonPortal } from "react-portal"
import BodyClassName from "react-body-classname"
import input from "./input"
import icon from "./icon"
import button from "./button"

export const Icon = icon
export const Button = button
export const Portal = CommonPortal

export const Input = input

export const Hyperlink = ({ className, href, children, ...rest }) => {
  return (
    <a className={classNames(className, "hyperlink")} href={href} {...rest}>
      {children}
    </a>
  )
}

Hyperlink.defaultProps = {
  className: "",
  href: "#"
}

Hyperlink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired
}

export const Buttons = ({ className, ...rest }) => (
  <div className={classNames("buttons", className)} {...rest} />
)

export const Overlay = ({ children, className, onClose, ...rest }) => {
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }
  return (
    <BodyClassName className="overlay-active">
      <div className={classNames("overlay", className)} {...rest}>
        <div className={classNames("overlay-background")} />
        <div className={classNames("overlay-foreground")}>
          {children}
          <button
            className="overlay-close"
            disabled={!onClose}
            onClick={handleClose}
          >
            <Icon times />
          </button>
        </div>
      </div>
    </BodyClassName>
  )
}

export const Divider = props => (
  <div className="dividers">
    <div className="divider divider-left" />
    {props.content && <div className="divider-content">{props.content}</div>}
    <div className="divider divider-right" />
  </div>
)

export const Spinner = ({ className, ...rest }) => (
  <div className={classNames("spinner", className)} {...rest}>
    <Icon loading />
  </div>
)

export const Box = ({ children, className, ...rest }) => {
  const edges = (
    <div className="edges">
      <div className="edge edge-top" />
      <div className="edge edge-right" />
      <div className="edge edge-bottom" />
      <div className="edge edge-left" />
    </div>
  )
  if (children) {
    return (
      <div className={classNames("box", className)} {...rest}>
        {edges}
        {children}
      </div>
    )
  }
  return edges
}

// export const Markdown = ({ content }) => (
//   remark().use(reactRenderer, {
//     remarkReactComponents: {
//       a: Hyperlink,
//     },
//   }).processSync(content).contents
// );

export const Nav = () => {
  const handleClick = () => {
    localStorage.clear()
    window.location.href = "/"
  }
  return (
    <div className="nav">
      <div className="nav-items content">
        <Link className="nav-item nav-brand" to="/">
          Net worth calculator
        </Link>
        {/*<Link className="nav-item" to="/score">
          Score
        </Link>*/}
        {/*<Link className="nav-item" to="/about">
          About
        </Link>*/}
        <button className="nav-item" onClick={handleClick}>
          Start over
        </button>
      </div>
    </div>
  )
}

export const Header = () => (
  <header className="header">
    <Nav />
  </header>
)

export const Footer = () => (
  <footer className="footer">
    <Nav />
  </footer>
)

export const Star = ({ half }) => (
  <div className={classNames({ star: true, half: half })}>
    <Icon starred />
  </div>
)

export const Stars = ({ score }) => (
  <div className="stars" title={score}>
    <div className="stars-background">
      <Icon star />
      <Icon star />
      <Icon star />
      <Icon star />
      <Icon star />
    </div>
    <div className="stars-foreground">
      {score >= 1 && <Star />}
      {score >= 2 && <Star />}
      {score >= 3 && <Star />}
      {score >= 4 && <Star />}
      {score >= 5 && <Star />}
      {score % 1 !== 0 && <Star half key={score} />}
    </div>
  </div>
)
